import React, { useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, NavLink } from 'react-router-dom';
import { Button, Dropdown, Modal, Form } from 'react-bootstrap';
import {
  useGetlistAggregateWorkDataSpecialityQuery,
  useUsersWorklistforManagerQuery,
} from '../../slices/worklistsApiSlice.js';
import ChartJS from 'chart.js/auto';
import '../../utils/tableStack.css';

function ManagerReportsList() {
  const navigate = useNavigate();
  const [chartInstances, setChartInstances] = useState([]);
  const [selectedSpeciality, setSelectedSpeciality] = useState('All');
  const [selectedClinicalSites, setSelectedClinicalSites] = useState([]); // Store selected clinical sites
  const [selectedChart, setSelectedChart] = useState(null); // Full-screen chart data
  const [showModal, setShowModal] = useState(false); // Modal visibility
  const fullScreenChartRef = useRef(null); // Ref for the modal chart
  const { userInfo } = useSelector((state) => state.auth);
  const teamId = userInfo._id;
  const [isChartReady, setIsChartReady] = useState(false);
  const [chartData, setChartData] = useState([]);

  const chartRefs = useRef([]);
  chartRefs.current = Array(100)
    .fill()
    .map((_, i) => chartRefs.current[i] || React.createRef());

  const {
    data: teamAggregateWorkDataTreeMap = [],
    isLoading,
    isFetching,
    isSuccess,
    error,
  } = useGetlistAggregateWorkDataSpecialityQuery(teamId);

  const {
    data: usersWorklist = [],
    isLoadingUW,
    isFetchingUW,
    isSuccessUW,
    errorUW,
  } = useUsersWorklistforManagerQuery(teamId);

  // console.log('teamAggregateWorkDataTreeMap ', teamAggregateWorkDataTreeMap);
  const specialities = [
    ...new Set(teamAggregateWorkDataTreeMap.map((entry) => entry.speciality)),
  ];

  // Extract all clinical sites
  const allClinicalSites = [
    ...new Set(teamAggregateWorkDataTreeMap.map((entry) => entry.clinicalSite)),
  ];

  //console.log('usersWorklist ', usersWorklist);

  useEffect(() => {
    if (chartRefs.current.every((ref) => ref.current)) {
      let filteredData =
        selectedSpeciality === 'All'
          ? [...teamAggregateWorkDataTreeMap] // Create a shallow copy
          : teamAggregateWorkDataTreeMap.filter(
              (entry) => entry.speciality === selectedSpeciality
            );

      /*// Sort by speciality (alphabetical order)
      filteredData = filteredData.sort((a, b) => {
        if (a.speciality < b.speciality) return -1;
        if (a.speciality > b.speciality) return 1;
        return 0;
      });
*/

      // Enrich data with worklistId from usersWorklist
      filteredData = filteredData.map((entry) => {
        //console.log('Processing Entry:', entry);

        const matchingWorklist = usersWorklist.find((worklist) => {
          //console.log('Comparing:', worklist.user._id, entry.staffid);
          return worklist.user._id === entry.staffid; // Ensure correct fields are used here
        });

        if (!matchingWorklist) {
          // console.warn('No matching worklist found for entry:', entry);
        }

        return {
          ...entry,
          worklistId: matchingWorklist ? matchingWorklist.worklistId : null, // Add worklistId or null if not found
        };
      });

      // console.log('filteredData ', filteredData);
      // Track processed staff IDs to avoid duplicates
      const processedStaffIds = new Set();

      const instances = chartRefs.current
        .map((ref, index) => {
          const staff = filteredData[index];
          if (!staff) return null;

          // Skip creating a chart if this staff ID is already processed
          if (processedStaffIds.has(staff.staffid)) {
            return null;
          }

          // Mark this staff ID as processed
          processedStaffIds.add(staff.staffid);

          const staffData = filteredData.filter(
            (entry) => entry.staffid === staff.staffid
          );

          const days = [...new Set(staffData.map((entry) => entry.day))];
          const clinicalSites = [
            ...new Set(staffData.map((entry) => entry.clinicalSite)),
          ];

          // Filter datasets and totalHours based on selected clinical sites
          const filteredClinicalSites = selectedClinicalSites.length
            ? clinicalSites.filter((site) =>
                selectedClinicalSites.includes(site)
              )
            : clinicalSites;

          // Filter staffData based on selectedClinicalSites or include all clinical sites if none are selected

          // Get unique clinical sites based on the staffData (for the staff member)
          const uniqueClinicalSites = [
            ...new Set(staffData.map((entry) => entry.clinicalSite)),
          ];

          // Filter staffData based on selectedClinicalSites or include all clinical sites (based on the staffData) if none are selected
          const filteredStaffData = staffData.filter(
            (entry) =>
              selectedClinicalSites.length
                ? selectedClinicalSites.includes(entry.clinicalSite)
                : true // If no sites are selected, include all sites that staff member has data for
          );

          // Use selectedClinicalSites if any are selected, otherwise use the uniqueClinicalSites
          const datasets = (
            selectedClinicalSites.length
              ? selectedClinicalSites
              : uniqueClinicalSites
          )
            .map((site) => {
              // Calculate the total work time for the specific site (from filtered staff data)
              const totalWorkTimeForSite = filteredStaffData
                .filter((item) => item.clinicalSite === site)
                .reduce((acc, item) => acc + item.theWorkTime, 0);

              // Calculate the total work time across all sites (from filtered staff data)
              const totalWorkTime = filteredStaffData.reduce(
                (acc, item) => acc + item.theWorkTime,
                0
              );

              // Calculate the percentage of work time for the specific site
              const percentage = totalWorkTime
                ? ((totalWorkTimeForSite / totalWorkTime) * 100).toFixed(2)
                : 0;

              // Return the dataset object with the calculated values
              return {
                label: percentage > 0 ? `${site} (${percentage}%)` : null, // Exclude 0% in label
                data: days.map((day) =>
                  filteredStaffData
                    .filter(
                      (entry) =>
                        entry.day === day && entry.clinicalSite === site
                    )
                    .reduce((total, entry) => total + entry.theWorkTime, 0)
                ),
                backgroundColor: `rgba(${Math.floor(
                  Math.random() * 255
                )}, ${Math.floor(Math.random() * 255)}, ${Math.floor(
                  Math.random() * 255
                )}, 0.6)`,
                borderColor: `rgba(${Math.floor(
                  Math.random() * 255
                )}, ${Math.floor(Math.random() * 255)}, ${Math.floor(
                  Math.random() * 255
                )}, 1)`,
                borderWidth: 1,
              };
            })
            .filter((dataset) => dataset.label !== null); // Filter out datasets with 0% labels

          /*
//_____________________________________________________________________________________________________
          const datasets = filteredClinicalSites.map((site) => {
            // Calculate the total work time for the specific site
            const totalWorkTimeForSite = staffData
              .filter((item) => item.clinicalSite === site)
              .reduce((acc, item) => acc + item.theWorkTime, 0);

            // Calculate the total work time across all sites
            const totalWorkTime = staffData.reduce(
              (acc, item) => acc + item.theWorkTime,
              0
            );

            // Calculate the percentage of work time for the specific site
            const percentage = (
              (totalWorkTimeForSite / totalWorkTime) *
              100
            ).toFixed(2);

            // Return the dataset object with the calculated values
            return {
              label: `${site} (${percentage}%)`,
              data: days.map((day) =>
                staffData
                  .filter(
                    (entry) => entry.day === day && entry.clinicalSite === site
                  )
                  .reduce((total, entry) => total + entry.theWorkTime, 0)
              ),
              backgroundColor: `rgba(${Math.floor(
                Math.random() * 255
              )}, ${Math.floor(Math.random() * 255)}, ${Math.floor(
                Math.random() * 255
              )}, 0.6)`,
              borderColor: `rgba(${Math.floor(
                Math.random() * 255
              )}, ${Math.floor(Math.random() * 255)}, ${Math.floor(
                Math.random() * 255
              )}, 1)`,
              borderWidth: 1,
            };
          });

          */
          //____________________________________________________________________________________

          const totalHours = staffData
            .filter((entry) =>
              selectedClinicalSites.length
                ? selectedClinicalSites.includes(entry.clinicalSite)
                : true
            )
            .reduce((sum, entry) => sum + entry.theWorkTime, 0);

          const chartData = {
            labels: days,
            datasets,
          };

          const chartTitle = `${staff.name} - ${
            staff.speciality
          } (Total Hours: ${totalHours.toFixed(2)})`;

          return new ChartJS(ref.current, {
            type: 'bar',
            data: chartData,
            options: {
              plugins: {
                title: {
                  display: true,
                  text: `${staff.name} - ${
                    staff.speciality
                  } (Total Hours: ${totalHours.toFixed(2)})`,
                },
              },
              onClick: () => {
                setSelectedChart({ staff, chartData, title: chartTitle }); // Pass the chart title
                setShowModal(true);
              },
              scales: {
                x: { stacked: true },
                y: { stacked: true, beginAtZero: true },
              },
            },
          });
        })
        .filter((instance) => instance !== null);

      setChartInstances(instances);

      return () => instances.forEach((instance) => instance.destroy());
    }
  }, [
    teamAggregateWorkDataTreeMap,
    selectedSpeciality,
    selectedClinicalSites,
    usersWorklist,
  ]);

  //__________
  //___________________________________________________________

  //___________________________________________________________________
  useEffect(() => {
    if (showModal && fullScreenChartRef.current && selectedChart) {
      const { chartData, staff } = selectedChart;
      //console.log('chartData ', chartData);
      // console.log('staff ', staff);

      new ChartJS(fullScreenChartRef.current, {
        type: 'bar',
        data: chartData,
        options: {
          plugins: {
            title: {
              display: true,
              text: `${staff.name} - ${staff.speciality}....`,
            },
          },
          scales: {
            x: { stacked: true },
            y: { stacked: true, beginAtZero: true },
          },
        },
      });
    }
  }, [showModal, selectedChart]);

  const handleSpecialityChange = (speciality) => {
    setSelectedSpeciality(speciality);
  };

  const handleClinicalSiteChange = (e) => {
    const { value, checked } = e.target;

    setSelectedClinicalSites((prevSelected) => {
      if (checked) {
        return [...prevSelected, value];
      } else {
        return prevSelected.filter((site) => site !== value);
      }
    });
  };

  const handleSelectAllSites = () => {
    if (selectedClinicalSites.length === allClinicalSites.length) {
      setSelectedClinicalSites([]); // Deselect all
    } else {
      setSelectedClinicalSites(allClinicalSites); // Select all
    }
  };

  /*
  const handlePrint = () => {
    if (chartRefs.current.length) {
      const printWindow = window.open('', '_blank');
      printWindow.document.write(
        '<html><head><title>Charts</title></head><body>'
      );

      chartRefs.current.forEach((ref, index) => {
        if (ref.current) {
          const dataUrl = ref.current.toDataURL();
          printWindow.document.write(`<div style="margin-bottom: 20px;">
            <img src="${dataUrl}" style="width: 100%; max-width: 600px;" />
            <p>Chart ${index + 1}</p>
          </div>`);
        }
      });

      printWindow.document.write(
        '<script>window.onload = function() { window.print(); window.close(); }</script>'
      );
      printWindow.document.write('</body></html>');
      printWindow.document.close();
    } else {
      alert('No charts available to print.');
    }
  };

  */

  const handlePrint = () => {
    if (chartRefs.current.length) {
      const printWindow = window.open('', '_blank');
      printWindow.document.write(`
        <html>
          <head>
            <title>Charts</title>
            <style>
              @media print {
                body {
                  margin: 0;
                  padding: 0;
                  font-family: Arial, sans-serif;
                }
                .chart-container {
                  display: grid;
                  grid-template-columns: repeat(3, 1fr); /* Grid layout for 3 columns */
                  gap: 20px;
                  padding: 20px; /* Padding for better aesthetics */
                  background-color: #f0f0f0; /* Light grey background */
                  border-radius: 10px; /* Rounded corners for the container */
                }
                .chart {
                  background-color: #ffffff; /* White background for each chart */
                  border: 2px solid #ffffff; /* White border around each chart */
                  border-radius: 8px; /* Rounded corners for each chart */
                  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Optional shadow */
                  padding: 10px; /* Padding inside the chart container */
                  page-break-inside: avoid; /* Prevent page break inside a chart */
                }
                .chart img {
                  width: 100%; /* Scale the image to fit within the container */
                  height: auto;
                }
                .chart p {
                  text-align: center;
                  margin-top: 5px;
                }
              }
              /* On-screen styles */
              .chart-container {
                display: grid;
                grid-template-columns: repeat(3, 1fr); /* Grid layout for 3 columns */
                gap: 20px;
                padding: 20px;
                background-color: #f0f0f0;
                border-radius: 10px;
              }
              .chart {
                background-color: #ffffff;
                border: 2px solid #ffffff;
                border-radius: 8px;
                box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
                padding: 10px;
              }
              .chart img {
                width: 100%;
                height: auto;
              }
            </style>
          </head>
          <body>
            <div class="chart-container">
      `);

      chartRefs.current.forEach((ref, index) => {
        if (ref.current) {
          const dataUrl = ref.current.toDataURL();
          printWindow.document.write(`
            <div class="chart">
              <img src="${dataUrl}" />
              <p>Chart ${index + 1}</p>
            </div>
          `);
        }
      });

      printWindow.document.write(`
            </div>
            <script>
              window.onload = function() {
                window.print();
                window.close();
              };
            </script>
          </body>
        </html>
      `);

      printWindow.document.close();
    } else {
      alert('No charts available to print.');
    }
  };

  //___________________________________________________________________________________________________________

  if (isLoading || isFetching || isLoadingUW || isFetchingUW) {
    return (
      <p>
        <h1>Loading......</h1>
      </p>
    );
  }

  if (!teamAggregateWorkDataTreeMap.length) {
    return <p>No data available.</p>;
  }

  return (
    <>
      <Button variant='outline-secondary' onClick={() => navigate(-1)}>
        Go Back
      </Button>
      <h2>{userInfo.directorateName} Speciality Charts</h2>

      <div
        style={{
          display: 'flex',
          gap: '10px', // Space between buttons
          marginBottom: '20px', // Space below the button group
          justifyContent: 'flex-start', // Align buttons to the left
        }}
      >
        {/* Speciality Dropdown */}
        <Dropdown>
          <Dropdown.Toggle variant='primary' id='speciality-dropdown'>
            {selectedSpeciality === 'All'
              ? 'All Specialities'
              : selectedSpeciality}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => handleSpecialityChange('All')}>
              All Specialities
            </Dropdown.Item>
            {specialities.map((speciality) => (
              <Dropdown.Item
                key={speciality}
                onClick={() => handleSpecialityChange(speciality)}
              >
                {speciality}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>

        {/* Clinical Sites Dropdown */}
        <Dropdown>
          <Dropdown.Toggle variant='primary' id='clinical-sites-dropdown'>
            {selectedClinicalSites.length
              ? `${selectedClinicalSites.length} Site(s) Selected`
              : 'Select Clinical Sites'}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={handleSelectAllSites}>
              {selectedClinicalSites.length === allClinicalSites.length
                ? 'Deselect All'
                : 'Select All'}
            </Dropdown.Item>
            {allClinicalSites.map((site) => (
              <Form.Check
                key={site}
                type='checkbox'
                label={site}
                value={site}
                checked={selectedClinicalSites.includes(site)}
                onChange={handleClinicalSiteChange}
                custom
              />
            ))}
          </Dropdown.Menu>
        </Dropdown>

        {/* Print Button */}
        <Button variant='primary' size='sm' onClick={handlePrint}>
          Print Chart
        </Button>
      </div>

      {/* Chart container with responsive grid */}
      <div
        className='chart-container'
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)', // Default for large screens
          gap: '20px',
          padding: '20px', // Add padding for better aesthetics
          backgroundColor: '#f0f0f0', // Light grey background
          borderRadius: '10px', // Rounded corners for the container
        }}
      >
        {chartRefs.current.map((ref, index) => (
          <div
            key={index}
            style={{
              backgroundColor: '#ffffff', // White background for individual charts
              border: '2px solid #ffffff', // White border around each chart
              borderRadius: '8px', // Rounded corners for each chart
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Optional shadow for better aesthetics
              padding: '10px', // Padding inside the chart container
            }}
          >
            <canvas ref={ref} />
          </div>
        ))}
      </div>

      {/* Modal for Full-Screen Chart */}
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        size='lg'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{selectedChart?.title || 'Chart Details'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedChart && (
            <NavLink
              to={{
                pathname: `/manager/worklist/${selectedChart.staff.worklistId}/edit/${selectedChart?.staff.staffid}`,
                state: { theName: selectedChart.staff.name, worlkistId: '1' },
              }}
              style={{ display: 'block', textDecoration: 'none' }}
            >
              <canvas
                ref={fullScreenChartRef}
                style={{ cursor: 'pointer' }}
              ></canvas>
            </NavLink>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ManagerReportsList;
