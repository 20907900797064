import { Chart as ChartJS } from 'chart.js/auto';
import { TreemapController, TreemapElement } from 'chartjs-chart-treemap';

import React, { useRef, useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import {
  useGetTeamAggregateWorkDataActivityQuery,
  useGetTeamAggregateWorkDataSpecialityQuery,
} from '../../slices/worklistsApiSlice.js';
import { FaLayerGroup } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useGetUsersManagerQuery } from '../../slices/usersApiSlice.js';

import '../../utils/tableStack.css';
//import Message from './Message.jsx';
//import Loader from './Loader.jsx';

// Register the plugins to all charts:
ChartJS.register(TreemapController, TreemapElement);

function ManagerReports() {
  const navigate = useNavigate();
  const location = useLocation();
  const [show, setShow] = useState(false);

  const [chartInstances, setChartInstances] = useState([]);
  const { userInfo } = useSelector((state) => state.auth);
  const myUserId = userInfo._id;
  const teamId = userInfo._id;

  //const { data, worklistId, theheading } = location.state || {};
  const chartRefs = useRef([]);
  chartRefs.current = Array(9)
    .fill()
    .map((_, i) => chartRefs.current[i] || React.createRef());

  const [isChartReady, setIsChartReady] = useState(false);

  const {
    data: teamAggregateWorkDataTreeMap = [],
    isLoading,
    error,
    refetch,
  } = useGetTeamAggregateWorkDataActivityQuery(teamId);

  const {
    data: teamAggregateWorkDataTreeMapSpeciality = [],
    isLoading: isLoadingsp,
    error: errorsp,
    refetch: refetchsp,
  } = useGetTeamAggregateWorkDataSpecialityQuery(teamId);

  const handleShow = () => setShow(true);
  const handleClose = () => {
    chartInstances.forEach((instance) => instance.destroy());
    setChartInstances([]);
    setShow(false);
  };

  console.log('TreemapChart2 : data', teamAggregateWorkDataTreeMap);

  console.log('TreemapChart2 : data', teamAggregateWorkDataTreeMapSpeciality);

  console.log('TreemapChart2  userInfo', userInfo);

  console.log('TreeMap WorkVolumeChart : userId', myUserId);
  // ${theheading}

  const {
    data: users,
    isLoading: loadingUsers,
    error: errorUsers,
  } = useGetUsersManagerQuery(userInfo._id);

  useEffect(() => {
    if (chartRefs.current.every((ref) => ref.current)) {
      const instances = chartRefs.current.map((ref, index) => {
        //______________________________________________________________________________________
        if (index === 0) {
          const totalWorkTime = teamAggregateWorkDataTreeMap
            .reduce((acc, item) => acc + item.theWorkTime, 0)
            .toFixed(2);

          const Staff_No = (users || []).reduce((sum, user) => sum + 1, 0);

          const totalWorkTimePlugin = {
            id: 'totalWorkTimePlugin',
            afterDatasetsDraw: (chart) => {
              const {
                ctx,
                chartArea: { top, bottom, left, right, width, height },
              } = chart;
              ctx.save();
              ctx.font = 'bold 20px Arial';
              ctx.fillStyle = 'black';
              ctx.textAlign = 'center';
              ctx.textBaseline = 'middle';
              ctx.fillText(
                `Staff: ${Staff_No}`,
                left + width / 2,
                top + height / 2
              );
              ctx.restore();
            },
          };

          return new ChartJS(ref.current, {
            type: 'bar',

            onClick: (event) => {
              const chart = ChartJS.getChart(ref.current);
              if (chart) {
                console.log('Chart clicked!'); // Debugging event
                const elements = chart.getElementsAtEventForMode(
                  event,
                  'nearest',
                  { intersect: true },
                  false
                );
                //console.log('Elements:', elements);

                if (elements.length > 0) {
                  const { index } = elements[0];
                  console.log(`Clicked on index: ${index}`);
                  if (index === 0) {
                    console.log('Redirecting to /manager/reports/List');
                    navigate('/manager/reports/List');
                  }
                } else {
                  console.log('No elements detected at click position.');
                }
              } else {
                console.log('Chart instance not found.');
              }
            },

            data: {
              labels: ['Total Staff'],
              datasets: [
                {
                  label: `Staff - ${userInfo.speciality}`,
                  data: [totalWorkTime],
                  backgroundColor: 'rgba(75, 192, 192, 0.6)',
                  borderColor: 'rgba(75, 192, 192, 1)',
                  borderWidth: 1,
                },
              ],
            },
            options: {
              onClick: (event, elements) => {
                const chart = ref.current; // Reference the correct chart
                console.log('Chart click detected'); // Debug log

                if (elements.length > 0) {
                  const { index } = elements[0];
                  //console.log(`Clicked on element index: ${index}`); // Debug log

                  if (index === 0) {
                    //console.log('Redirecting to /manager/reports/List'); // Debug log
                    navigate('/manager/reports/List');
                  }
                } else {
                  console.log('No elements detected at click position.');
                }
              },

              scales: {
                y: {
                  beginAtZero: true,
                },
              },
              plugins: {
                legend: {
                  display: true,
                },
                totalWorkTimePlugin: {},
              },
            },
            plugins: [totalWorkTimePlugin],
          });
        }

        //______________________________________________________________________________________________________________________________________
        if (index === 1) {
          // Calculate the total work time and format to 2 decimal places
          const totalWorkTime = teamAggregateWorkDataTreeMap
            .reduce((acc, item) => acc + item.theWorkTime, 0)
            .toFixed(2);

          // Define a custom plugin to draw the text
          const totalWorkTimePlugin = {
            id: 'totalWorkTimePlugin',
            afterDatasetsDraw: (chart) => {
              const {
                ctx,
                chartArea: { top, bottom, left, right, width, height },
              } = chart;
              ctx.save();
              ctx.font = 'bold 20px Arial';
              ctx.fillStyle = 'black';
              ctx.textAlign = 'center';
              ctx.textBaseline = 'middle';
              ctx.fillText(
                `Total Work Time: ${totalWorkTime}`,
                left + width / 2,
                top + height / 2
              );
              ctx.restore();
            },
          };

          // Bar chart for the second chart
          return new ChartJS(ref.current, {
            type: 'bar',
            data: {
              labels: ['Total Work Time'],
              datasets: [
                {
                  label: `Total Work Time - ${userInfo.speciality}`,
                  data: [totalWorkTime],
                  backgroundColor: 'rgba(75, 192, 192, 0.6)',
                  borderColor: 'rgba(75, 192, 192, 1)',
                  borderWidth: 1,
                },
              ],
            },
            options: {
              onClick: (event, elements) => {
                const chart = ref.current; // Reference the correct chart
                console.log('Chart click detected'); // Debug log

                if (elements.length > 0) {
                  const { index } = elements[0];
                  //console.log(`Clicked on element index: ${index}`); // Debug log

                  if (index === 0) {
                    //console.log('Redirecting to /manager/reports/List'); // Debug log
                    navigate('/manager/activityreport');
                  }
                } else {
                  //console.log('No elements detected at click position.');
                }
              },

              scales: {
                y: {
                  beginAtZero: true,
                },
              },
              plugins: {
                legend: {
                  display: true,
                },
                totalWorkTimePlugin: {}, // Include the custom plugin
              },
            },
            plugins: [totalWorkTimePlugin], // Register the custom plugin
          });
        } else if (index === 2) {
          // Bar chart for the second chart___________________________________________________________________________________________
          return new ChartJS(ref.current, {
            type: 'doughnut',
            data: {
              //labels: teamAggregateWorkDataTreeMap.map((item) => item.activity),
              datasets: [
                {
                  label: `Team Work Time - ${userInfo.speciality}`,
                  data: teamAggregateWorkDataTreeMap.map(
                    (item) => item.theWorkTime
                  ),
                  backgroundColor: 'rgba(75, 192, 192, 0.6)',
                  borderColor: 'rgba(75, 192, 192, 1)',
                  borderWidth: 1,
                },
              ],
            },
            options: {
              onClick: (event, elements) => {
                const chart = ref.current; // Reference the correct chart
                console.log('Chart click detected'); // Debug log

                if (elements.length > 0) {
                  const { index } = elements[0];
                  //console.log(`Clicked on element index: ${index}`); // Debug log

                  if (index === 0) {
                    //console.log('Redirecting to /manager/reports/List'); // Debug log
                    navigate('/manager/activityreport');
                  }
                } else {
                  //console.log('No elements detected at click position.');
                }
              },
              scales: {
                y: {
                  beginAtZero: true,
                },
              },
              plugins: {
                legend: {
                  display: true,
                },
              },
            },
          });
        } else if (index === 3) {
          // Bar chart for the second chart
          return new ChartJS(ref.current, {
            type: 'treemap',
            data: {
              datasets: [
                {
                  label: `Team Activity Chart for - ${userInfo.salutation} ${userInfo.name} - ${userInfo.speciality}`,
                  tree: teamAggregateWorkDataTreeMapSpeciality, //Speciality
                  key: 'theWorkTime',
                  groups: ['speciality'],
                  //groups: ['activity'],

                  spacing: 0.5,
                  borderWidth: 1,
                  borderColor: 'rgba(0,0,0,0.1)',
                  //backgroundColor: 'rgba(0,123,255,0.5)',
                  backgroundColor: (ctx) => {
                    console.log('backgroundColor: ctx', ctx);

                    if (
                      !ctx ||
                      !ctx.raw ||
                      !ctx.raw._data ||
                      !ctx.raw._data.num
                    ) {
                      return 'rgba(0, 123, 255, 0.4)';
                    }
                    const alpha = (1 + Math.log(ctx.raw._data.num)) / 5;
                    return `rgba(0, 128, 0, ${alpha})`;
                  },
                  hoverBackgroundColor: 'rgba(0,123,255,0.7)',

                  labels: {
                    display: true,
                    formatter: (ctx) => {
                      console.log('ctx inside formatter:', ctx);

                      if (ctx && ctx.raw.g && ctx.raw.v) {
                        return `${ctx.raw.g} - ${ctx.raw.v}`;
                      }
                      return 'blank'; // Return 'blank' if label or num is undefined
                    },
                    color: 'black',
                    font: {
                      size: 12,
                    },
                    align: 'center',
                    anchor: 'center',
                    clip: true,
                    overflow: 'fit',
                  },
                },
              ],
            },
            options: {
              onClick: (event, elements) => {
                const chart = ref.current; // Reference the correct chart
                console.log('Chart click detected'); // Debug log

                if (elements.length > 0) {
                  const { index } = elements[0];
                  //console.log(`Clicked on element index: ${index}`); // Debug log

                  if (index === 0) {
                    //console.log('Redirecting to /manager/reports/List'); // Debug log
                    navigate('/manager/activityreport');
                  }
                } else {
                  //console.log('No elements detected at click position.');
                }
              },

              plugins: {
                legend: {
                  display: true,
                },
              },
            },
          });
        } else {
          // Treemap chart for other charts
          return new ChartJS(ref.current, {
            type: 'treemap',
            data: {
              datasets: [
                {
                  label: `Team Activity Chart for - ${userInfo.salutation} ${userInfo.name} - ${userInfo.speciality}`,
                  tree: teamAggregateWorkDataTreeMap,
                  key: 'theWorkTime',
                  groups: ['activity'],
                  spacing: 0.5,
                  borderWidth: 1,
                  borderColor: 'rgba(0,0,0,0.1)',
                  //backgroundColor: 'rgba(0,123,255,0.5)',
                  backgroundColor: (ctx) => {
                    console.log('backgroundColor: ctx', ctx);

                    if (
                      !ctx ||
                      !ctx.raw ||
                      !ctx.raw._data ||
                      !ctx.raw._data.num
                    ) {
                      return 'rgba(0, 123, 255, 0.4)';
                    }
                    const alpha = (1 + Math.log(ctx.raw._data.num)) / 5;
                    return `rgba(0, 128, 0, ${alpha})`;
                  },
                  hoverBackgroundColor: 'rgba(0,123,255,0.7)',

                  labels: {
                    display: true,
                    formatter: (ctx) => {
                      console.log('ctx inside formatter:', ctx);

                      if (ctx && ctx.raw.g && ctx.raw.v) {
                        return `${ctx.raw.g} - ${ctx.raw.v}`;
                      }
                      return 'blank'; // Return 'blank' if label or num is undefined
                    },
                    color: 'black',
                    font: {
                      size: 12,
                    },
                    align: 'center',
                    anchor: 'center',
                    clip: true,
                    overflow: 'fit',
                  },
                },
              ],
            },
            options: {
              onClick: (event, elements) => {
                const chart = ref.current; // Reference the correct chart
                console.log('Chart click detected'); // Debug log

                if (elements.length > 0) {
                  const { index } = elements[0];
                  //console.log(`Clicked on element index: ${index}`); // Debug log

                  if (index === 0) {
                    //console.log('Redirecting to /manager/reports/List'); // Debug log
                    navigate('/manager/activityreport');
                  }
                } else {
                  //console.log('No elements detected at click position.');
                }
              },
              plugins: {
                legend: {
                  display: true,
                },
              },
            },
          });
        }
      });

      // Indicate that the charts are ready
      setIsChartReady(true);
      setChartInstances(instances);

      return () => {
        instances.forEach((instance) => instance.destroy());
      };
    }
  }, [
    teamAggregateWorkDataTreeMap,
    teamAggregateWorkDataTreeMapSpeciality,
    userInfo.name,
    users,
  ]);

  // Function to handle print action
  const handlePrint = () => {
    if (isChartReady && chartRefs.current.every((ref) => ref.current)) {
      chartRefs.current.forEach((ref) => {
        const canvas = ref.current;
        const dataUrl = canvas.toDataURL();
        const printWindow = window.open('', '_blank');
        printWindow.document.write(
          '<html><head><title>Activity Chart</title></head><body>'
        );
        printWindow.document.write(
          `<img src="${dataUrl}" onload="window.print();window.close()" />`
        );
        printWindow.document.write('</body></html>');
        printWindow.document.close();
      });
    }
  };

  return (
    <>
      <Button variant='outline-secondary' onClick={() => navigate(-1)}>
        Go Back
      </Button>
      <div>
        <h2> {userInfo.directorateName} Activity Charts</h2>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gap: '20px',
          }}
        >
          {chartRefs.current.map((ref, index) => (
            <div key={index}>
              <canvas ref={ref} />
            </div>
          ))}
        </div>
      </div>

      <Button variant='outline-primary' size='sm' onClick={handlePrint}>
        Print Charts
      </Button>
    </>
  );
}

export default ManagerReports;
