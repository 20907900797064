import { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import Message from '../../components/Message';
import Loader from '../../components/Loader';
import FormContainer from '../../components/FormContainer';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import {
  useGetClinicalSCommitmentDetailsQuery,
  useUpdateClinicalSCommitmentMutation,
} from '../../slices/clinicalSCommitmentsApiSlice';

import { useGetClinicalSitesNPQuery } from '../../slices/clinicalSitesApiSlice';

import { useGetUsersQuery } from '../../slices/usersApiSlice';

const ClinicalSCommitmentEditScreen = () => {
  const { pageNumber } = useParams();

  const {
    data: clinicalsitesdata,
    isLoading: isLoadingCS,
    error: errorCS,
    refetch: refetchCS,
  } = useGetClinicalSitesNPQuery();

  const {
    data: users,
    refetch: refetchu,
    isLoading: isLoadingu,
    error: erroru,
  } = useGetUsersQuery();

  const { id: clinicalSCommitmentId } = useParams();
  const { userInfo } = useSelector((state) => state.auth);

  const [clinicalSiteName, setClinicalSiteName] = useState('');
  const [comments, setComments] = useState('');
  const [userID, setUserID] = useState(userInfo._id);
  const [consultantName, setConsultantName] = useState(userInfo.name);

  const [activeYorN, setActiveYorN] = useState('');
  const [postHours, setPostHours] = useState('0');
  const [practicePlanHours, setPracticePlanHours] = useState('0');

  const [reviewDate, setReviewDate] = useState(new Date());
  const [effectiveDate, setEffectiveDate] = useState(new Date());

  const {
    data: clinicalSCommitment,
    isLoading,
    refetch,
    error,
  } = useGetClinicalSCommitmentDetailsQuery(clinicalSCommitmentId);

  //#f5f5dc
  const dropdownStyle = {
    backgroundColor: '#F5F5F5', //#F0E68C'   // '#f0f8ff'                 //'lightyellow',
    // Add other styles as needed
  };
  const textStyle = {
    backgroundColor: '#f5f5dc',
    // Add other styles as needed
  };

  const [updateClinicalSCommitment, { isLoading: loadingUpdate }] =
    useUpdateClinicalSCommitmentMutation();

  const navigate = useNavigate();

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      await updateClinicalSCommitment({
        clinicalSCommitmentId,
        clinicalSiteName,
        userID,
        consultantName,
        comments,
        activeYorN,
        postHours,
        practicePlanHours,
        reviewDate,
        effectiveDate,
      }).unwrap(); // NOTE: here we need to unwrap the Promise to catch any rejection in our catch block
      toast.success('Clinical Site Commitment updated');
      refetch();
      navigate('/admin/clinicalSCommitmentlist');
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  /*
    useEffect(() => {
        // Set an initial date when the component mounts
        const currentDate = new Date();
        const formattedDate = currentDate.toISOString().split('T')[0];
        setReviewDate(formattedDate);
        

    }, []); // The empty dependency array ensures this effect runs only once when the component mounts

*/

  useEffect(() => {
    if (clinicalSCommitment) {
      const reviewDate1 = new Date(clinicalSCommitment.reviewDate);
      setUserID(clinicalSCommitment.userID);
      setConsultantName(clinicalSCommitment.consultantName);

      //const reviewDate2 = reviewDate1.toISOString().slice(0, 10);
      //const reviewDate2 = reviewDate1 ? reviewDate1.toISOString().slice(0, 10) : '';
      //const reviewDate2 = (reviewDate1 ?? '').toISOString().slice(0, 10);

      let reviewDate2 = '';

      if (
        reviewDate1 &&
        reviewDate1 instanceof Date &&
        !isNaN(reviewDate1.getTime())
      ) {
        reviewDate2 = reviewDate1.toISOString().slice(0, 10);
        // Further processing with reviewDate2
      } else {
        reviewDate2 = '';
        //console.error('reviewDate1 is not a valid Date object or is null/undefined');
        // Handle the case where reviewDate1 is not a valid date or is null/undefined
      }

      const effectiveDate1 = new Date(clinicalSCommitment.effectiveDate);
      //const effectiveDate2 = effectiveDate1.toISOString().slice(0, 10);
      //const effectiveDate2 = (effectiveDate1 ?? '').toISOString().slice(0, 10);

      let effectiveDate2 = '';

      if (
        effectiveDate1 &&
        effectiveDate1 instanceof Date &&
        !isNaN(effectiveDate1.getTime())
      ) {
        effectiveDate2 = effectiveDate1.toISOString().slice(0, 10);
        // Further processing with reviewDate2
      } else {
        effectiveDate2 = '';
        //console.error('reviewDate1 is not a valid Date object or is null/undefined');
        // Handle the case where reviewDate1 is not a valid date or is null/undefined
      }

      setClinicalSiteName(clinicalSCommitment.clinicalSiteName);
      setComments(clinicalSCommitment.comments);
      setActiveYorN(clinicalSCommitment.activeYorN);
      setPostHours(clinicalSCommitment.postHours);
      setPracticePlanHours(clinicalSCommitment.practicePlanHours);
      setReviewDate(reviewDate2);
      setEffectiveDate(effectiveDate2);
    }
  }, [clinicalSCommitment]);

  if (isLoadingCS) {
    return <Loader />;
  }

  let sortedClinicalSitesData = [];
  if (clinicalsitesdata && clinicalsitesdata.length > 0) {
    sortedClinicalSitesData = [...clinicalsitesdata].sort((a, b) => {
      if (a.clinicalSiteName < b.clinicalSiteName) return -1;
      if (a.clinicalSiteName > b.clinicalSiteName) return 1;
      return 0;
    });
  }

  return (
    <>
      <Link to='/admin/clinicalSCommitmentlist' className='btn btn-light my-3'>
        Go Back
      </Link>
      <FormContainer>
        <h1>Edit Clinical Site Commitment</h1>
        {loadingUpdate && <Loader />}
        {isLoadingCS && <Loader />}

        {isLoading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error.data.message}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <div
              style={{
                border: '1px solid #ddd',
                padding: '10px',
                borderRadius: '10px',
                backgroundColor: '#F0F8FF',
              }}
            >
              <Form.Group controlId='name'>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  as='select'
                  placeholder='clinical Site Name'
                  value={clinicalSiteName}
                  onChange={(e) => setClinicalSiteName(e.target.value)}
                  style={dropdownStyle}
                >
                  <option value=''>Select a clinical site</option>

                  {sortedClinicalSitesData &&
                    sortedClinicalSitesData.map &&
                    sortedClinicalSitesData.map((clinSite) => (
                      <option
                        key={clinSite.clinicalSiteName}
                        value={clinSite.clinicalSiteName}
                      >
                        {clinSite.clinicalSiteName}
                      </option>
                    ))}

                  {/*users.map((clinicalSite) => (
                                    <option key={clinicalSite.name} value={clinicalSite.name}>
                                        {clinicalSite.name}
                                    </option>
                                )) */}
                </Form.Control>
              </Form.Group>

              <Form.Group controlId='postHours'>
                <Form.Label>Post Hours</Form.Label>
                <Form.Control
                  type='number'
                  placeholder='Post Hours'
                  value={postHours}
                  onChange={(e) => setPostHours(e.target.value)}
                  style={textStyle}
                />
              </Form.Group>

              <Form.Group controlId='practicePlanHours'>
                <Form.Label>Practice Plan Hours</Form.Label>
                <Form.Control
                  type='number'
                  placeholder='Practice Plan Hours'
                  value={practicePlanHours}
                  onChange={(e) => setPracticePlanHours(e.target.value)}
                  style={textStyle}
                />
              </Form.Group>

              <Form.Group controlId='effectiveDate'>
                <Form.Label>Effective Date </Form.Label>
                <Form.Control
                  type='date'
                  placeholder='Effective Date '
                  value={effectiveDate} // {effectiveDate.toISOString().split('T')[0]}  //{effectiveDate}
                  onChange={(e) => setEffectiveDate(e.target.value)}
                  style={textStyle}
                />
              </Form.Group>

              <Form.Group controlId='reviewDate'>
                <Form.Label>Review Date </Form.Label>

                <Form.Control
                  type='date'
                  value={reviewDate}
                  onChange={(e) => setReviewDate(e.target.value)}
                  style={textStyle}
                />
              </Form.Group>

              <Form.Group controlId='comments'>
                <Form.Label>Comments</Form.Label>

                <Form.Control
                  type='text'
                  placeholder='Comments'
                  value={comments}
                  onChange={(e) => setComments(e.target.value)}
                  style={textStyle}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId='activeYorN'>
                <Form.Label>Active Yes or No</Form.Label>
                <Form.Control
                  as='select'
                  placeholder='Active YorN'
                  value={activeYorN}
                  onChange={(e) => setActiveYorN(e.target.value)}
                  style={dropdownStyle}
                >
                  <option value={activeYorN}>{activeYorN}</option>
                  <option value='Yes'>Yes</option>
                  <option value='No'>No</option>
                </Form.Control>
              </Form.Group>
            </div>

            <Button
              type='submit'
              variant='primary'
              style={{ marginTop: '1rem' }}
            >
              Update
            </Button>
          </Form>
        )}
      </FormContainer>
    </>
  );
};

export default ClinicalSCommitmentEditScreen;
