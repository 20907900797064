import React, { useEffect, useState } from 'react';
import { Table, Form, Button, Row, Col, Modal, Card } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FaEdit, FaPlus, FaTrash, FaTimes, FaCheck } from 'react-icons/fa';
import { toast } from 'react-toastify';
import Message from '../../components/Message.jsx';
import Loader from '../../components/Loader.jsx';
import {
  useGetPrivilegesQuery,
  useCreatePrivilegeMutation,
} from '../../slices/privilegeApiSlice.js';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';

import { DataGrid, GridToolbar, gridClasses } from '@mui/x-data-grid';
import { darken, lighten, styled } from '@mui/material/styles';
import '../../utils/tableStack.css';

import {
  grey,
  yellow,
  common,
  orange,
  lightGreen,
  red,
} from '@mui/material/colors';

const dropdownStyle = {
  backgroundColor: '#F5F5F5', //'#F0E68C'                 //'lightyellow',
  // Add other styles as needed
};
const textStyle = {
  backgroundColor: '#f5f5dc',
  // Add other styles as needed
};

const PrivilegeScreen = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Fetch privileges using the hook
  const {
    data: privileges,
    isLoading,
    error,
    refetch,
  } = useGetPrivilegesQuery();

  // Mutation hook to create a new privilege
  const [createSpeciality, { isLoading: loadingCreate }] =
    useCreatePrivilegeMutation();

  const createSpecialityHandler = async () => {
    if (window.confirm('Are you sure you want to create a new Speciality?')) {
      try {
        await createSpeciality();
        refetch(); // Refetch privileges after creation
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    }
  };

  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);

  // Monitor window width to adjust layout
  useEffect(() => {
    if (privileges) {
      refetch();

      /* const formattedRows = privileges
        .slice()
        .sort((a, b) => {
          // Compare by speciality
          const specialityComparison = a.specialityName.localeCompare(
            b.specialityName
          );

          if (specialityComparison !== 0) {
            return specialityComparison;
          }

          // If speciality is the same, you might want to add additional comparison logic here
          return 0; // Ensure a consistent return value
        })
*/

      //___________________________________________________________________________
      const formattedRows = privileges
        .slice()
        .sort((a, b) => {
          // Check if either specialityName starts with 'z'
          const aStartsWithZ = a.specialityName.toLowerCase().startsWith('z');
          const bStartsWithZ = b.specialityName.toLowerCase().startsWith('z');

          if (aStartsWithZ && !bStartsWithZ) {
            return -1; // a should come before b
          }
          if (!aStartsWithZ && bStartsWithZ) {
            return 1; // b should come before a
          }

          // If both or neither start with 'z', compare normally
          const specialityComparison = a.specialityName.localeCompare(
            b.specialityName
          );

          if (specialityComparison !== 0) {
            return specialityComparison;
          }

          // If speciality is the same, you might want to add additional comparison logic here
          return 0; // Ensure a consistent return value
        })

        //_________________________________________________________________________

        .map((item) => ({
          id: item._id.toString(), // Ensure each item has a unique ID and convert ObjectId to string
          speciality: item.specialityName, // Access the nested user object
          isActive: item.isActive,
          //createdAt: new Date(item.createdAt).toLocaleDateString('en-GB'), // Format date to dd/mm/yyyy,
        }));

      setLoading(false);

      setRows(formattedRows);
    }

    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [privileges]);

  const navigate = useNavigate();

  const handleEdit = (id) => {
    //console.log(`Edit item with ID: ${id}`);
    //console.log(`Edit item with ID: ${uKey}`);

    navigate(`/admin/privilege/${id}/edit`);
  };

  const handleDelete = (id) => {
    //console.log(`Delete item with ID: ${id}`);
    // Add your delete handling logic here
  };

  const handleEditPrivileges = (id) => {
    //console.log(`Delete item with ID: ${id}`);
    navigate(`/admin/privileges/${id}/list`);

    // Add your delete handling logic here
  };

  const columns = [
    {
      field: 'speciality',
      headerName: 'Speciality Name',
      width: 500,
      hide: true,
    },

    {
      field: 'isActive',
      headerName: 'Active',
      width: 150,
      renderCell: (params) =>
        params.value ? (
          <FaCheck style={{ color: 'green' }} />
        ) : (
          <FaTimes style={{ color: 'red' }} />
        ),
    },

    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Button
            size='small'
            variant='outlined'
            color='info'
            //onClick={() => handleEdit(params.id, params.row.UKey))}
            onClick={() => handleEdit(params.id)}
          >
            <FaEdit /> Edit
          </Button>
          <Button
            size='small'
            variant='contained'
            color='warning'
            onClick={() => handleEditPrivileges(params.id)}
          >
            <FaEdit style={{ color: 'brown' }} /> Edit Privileges
          </Button>
        </Box>
      ),
    },
  ];

  const [responsive, setResponsive] = useState('vertical');
  const [tableBodyHeight, setTableBodyHeight] = useState('400px');
  const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState('');
  const [searchBtn, setSearchBtn] = useState(true);
  const [downloadBtn, setDownloadBtn] = useState(true);
  const [printBtn, setPrintBtn] = useState(true);
  const [viewColumnBtn, setViewColumnBtn] = useState(true);
  const [filterBtn, setFilterBtn] = useState(true);

  const options = {
    search: searchBtn,
    download: downloadBtn,
    print: printBtn,
    viewColumns: viewColumnBtn,
    filter: filterBtn,
    filterType: 'dropdown',
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    onTableChange: (action, state) => {
      //console.log(action);
      console.dir(state);
    },
  };

  //valueGetter: (value, row) => `${row.firstName || ''} ${row.lastName || ''}`,

  /* // Otherwise filter will be applied on fields such as the hidden column id
  const columns = React.useMemo(
    () => data.columns.filter((column) => VISIBLE_FIELDS.includes(column.field)),
    [data.columns],
  ); */

  //<MUIDataTable title={'ACME Employee list'} data={data} columns={columns} options={options} />

  //options={options}

  //
  const getRowSpacing = React.useCallback((params) => {
    return {
      top: params.isFirstVisible ? 0 : 1,
      bottom: params.isLastVisible ? 0 : 1,
    };
  }, []);
  //
  //
  const getBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);

  const getHoverBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

  const getSelectedBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

  const getSelectedHoverBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.4);
  //
  //

  const StyledDataGrid = styled(DataGrid)`
    & .${gridClasses.row}.lightGreenRow {
      background-color: ${lightGreen[100]};
    }
    & .${gridClasses.row}.yellowRow {
      background-color: ${yellow[100]};
    }
    & .${gridClasses.row}.redRow {
      background-color: ${red[100]};
    }

    & .${gridClasses.row}.orangeRow {
      background-color: ${orange[100]};
    }

    &
      .${gridClasses.row}:nth-child(odd):not(.yellowRow):not(.orangeRow):not(
        .lightGreenRow
      ):not(.redRow) {
      background-color: ${grey[200]};
    }

    &
      .${gridClasses.row}:nth-child(even):not(.yellowRow):not(.orangeRow):not(
        .lightGreenRow
      ):not(.redRow) {
      background-color: ${common.white}; // Assuming 'common.white' was intended
    }
  `;

  const renderErrorOrLoading = () => {
    if (isLoading) return <Loader />;
    if (error)
      return (
        <Message variant='danger'>
          {error?.data?.message || error.error}
        </Message>
      );
    return null;
  };

  const renderNoPrivileges = () => (
    <Col md={12} className='text-end'>
      <Button className='my-3' onClick={createSpecialityHandler}>
        <FaPlus /> Create Speciality
      </Button>
    </Col>
  );

  const renderPrivilegesCards = () => (
    <div>
      {privileges.length === 0 ? (
        renderNoPrivileges()
      ) : (
        <Col md={12} className='text-end'>
          <Button className='my-3' onClick={createSpecialityHandler}>
            <FaPlus /> Create Speciality
          </Button>
        </Col>
      )}
      {privileges
        .slice()
        .sort((a, b) => {
          // Check if either specialityName starts with 'z'
          const aStartsWithZ = a.specialityName.toLowerCase().startsWith('z');
          const bStartsWithZ = b.specialityName.toLowerCase().startsWith('z');

          if (aStartsWithZ && !bStartsWithZ) {
            return -1; // a should come before b
          }
          if (!aStartsWithZ && bStartsWithZ) {
            return 1; // b should come before a
          }

          // If both or neither start with 'z', compare normally
          const specialityComparison = a.specialityName.localeCompare(
            b.specialityName
          );

          if (specialityComparison !== 0) {
            return specialityComparison;
          }

          // If speciality is the same, you might want to add additional comparison logic here
          return 0; // Ensure a consistent return value
        }) // Sorting based on createdAt
        .map((item) => (
          <Card
            key={item._id}
            className={`mb-3 ${
              item.specialityName.startsWith('z') ? 'highlightRow' : ''
            }`}
          >
            <Card.Body
              style={{
                backgroundColor: item.specialityName.startsWith('z')
                  ? 'rgb(254, 194, 192)'
                  : 'rgb(238,239,206)',
              }}
            >
              <Card.Text>
                <strong>Speciality Name:</strong> {item.specialityName}
              </Card.Text>
              <Card.Text>
                <strong>Active:</strong>{' '}
                {item.isActive ? (
                  <FaCheck style={{ color: 'green' }} />
                ) : (
                  <FaTimes style={{ color: 'red' }} />
                )}
              </Card.Text>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <LinkContainer to={`/admin/privilege/${item._id}/edit`}>
                  <Button
                    size='sm'
                    variant='outline-info'
                    className='btn-sm mx-2'
                  >
                    <FaEdit /> Edit Item
                  </Button>
                </LinkContainer>
                <LinkContainer to={`/admin/privileges/${item._id}/list`}>
                  <Button size='sm' variant='warning' className='btn-sm'>
                    <FaTrash style={{ color: 'white' }} /> Edit Privileges
                  </Button>
                </LinkContainer>
              </div>
            </Card.Body>
          </Card>
        ))}
    </div>
  );

  const renderPrivilegesTable = () => (
    <Col md={12} className='d-flex justify-content-between'>
      {renderErrorOrLoading() || (
        <Box sx={{ height: 700, width: '100%' }}>
          {privileges.length === 0 ? (
            renderNoPrivileges()
          ) : (
            <Col md={12} className='text-end'>
              <Button className='my-3' onClick={createSpecialityHandler}>
                <FaPlus /> Create Speciality....
              </Button>
            </Col>
          )}
          <StyledDataGrid
            density='compact'
            // rows={privileges || []} // Assuming rows is the privileges data
            rows={rows}
            columns={columns} // Assuming you define columns elsewhere
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
            loading={loadingCreate} // Using loadingCreate here to avoid conflict
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 50,
                },
              },
            }}
            pageSizeOptions={[50]}
            checkboxSelection
            disableRowSelectionOnClick
            getRowClassName={(params) => {
              if (params.row.speciality.startsWith('Lunch Break'))
                return 'lightGreenRow';
              if (params.row.speciality.startsWith('For Noting'))
                return 'orangeRow';
              if (params.row.speciality.startsWith('z')) return 'redRow';
              return '';
            }}
          />
        </Box>
      )}
    </Col>
  );

  return (
    <>
      <Link to='/' className='btn btn-light mb-4'>
        Go Back
      </Link>
      <Row>
        <Col>
          <h3>Privileges by Speciality</h3>
        </Col>
      </Row>

      {windowWidth < 900
        ? renderErrorOrLoading() || renderPrivilegesCards()
        : renderPrivilegesTable()}
    </>
  );
};

export default PrivilegeScreen;
