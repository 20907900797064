import { Chart as ChartJS, defaults } from 'chart.js/auto';
import { Bar } from 'react-chartjs-2';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React, { useEffect, useState, useRef } from 'react';
import { FaCalendarWeek } from 'react-icons/fa';

import Message from './Message.jsx';
import Loader from './Loader.jsx';

import { useGetAggregateWorkDataQuery } from '../slices/worklistsApiSlice.js';

import '../utils/tableStack.css';

function WorkVolumeChart({ userId }) {
  console.log('WorkVolumeChart : userId', userId);
  const [show, setShow] = useState(false);
  const chartRef = useRef(null);

  const {
    data: aggregateWorkData = [], // Provide a default value
    isLoading,
    error,
    refetch,
  } = useGetAggregateWorkDataQuery(userId);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    // Clean up the chart instance before creating a new one
    return () => {
      if (window.Chart) {
        Object.keys(window.Chart.instances).forEach((key) => {
          window.Chart.instances[key].destroy();
        });
      }
    };
  }, [aggregateWorkData]);

  // Group data by day
  const groupedData = aggregateWorkData.reduce((acc, item) => {
    const { day, clinicalSite, theWorkTime } = item;
    if (!acc[day]) {
      acc[day] = {};
    }
    if (!acc[day][clinicalSite]) {
      acc[day][clinicalSite] = 0;
    }
    acc[day][clinicalSite] += theWorkTime;
    return acc;
  }, {});

  // Calculate the total work time for each day
  const totalWorkTimePerDay = Object.keys(groupedData).reduce((acc, day) => {
    acc[day] = Object.values(groupedData[day]).reduce(
      (sum, time) => sum + time,
      0
    );
    return acc;
  }, {});

  // Find the highest daily hours for the week
  const highestDailyHours = Math.max(...Object.values(totalWorkTimePerDay));

  // Extract labels and datasets
  const labels = Object.keys(groupedData);
  const clinicalSites = [
    ...new Set(aggregateWorkData.map((item) => item.clinicalSite)),
  ];
  const datasets = clinicalSites.map((site) => {
    const totalWorkTimeForSite = aggregateWorkData
      .filter((item) => item.clinicalSite === site)
      .reduce((acc, item) => acc + item.theWorkTime, 0);
    const totalWorkTime = aggregateWorkData.reduce(
      (acc, item) => acc + item.theWorkTime,
      0
    );
    const percentage = ((totalWorkTimeForSite / totalWorkTime) * 100).toFixed(
      2
    );
    return {
      label: `${site} (${percentage}%)`,
      data: labels.map((day) => groupedData[day][site] || 0),
      backgroundColor: `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(
        Math.random() * 255
      )}, ${Math.floor(Math.random() * 255)}, 0.6)`,
    };
  });

  // Calculate the overall running total in minutes
  const overallTotalWorkTimeInMinutes = aggregateWorkData.reduce(
    (total, item) => {
      const hours = Math.floor(item.theWorkTime);
      const minutes = (item.theWorkTime - hours) * 60;
      return total + hours * 60 + minutes;
    },
    0
  );

  // Convert the total minutes to hours and minutes
  const overallTotalWorkHours = Math.floor(overallTotalWorkTimeInMinutes / 60);
  const overallTotalWorkMinutes = overallTotalWorkTimeInMinutes % 60;

  // Format the data for the Bar component
  const chartData = {
    labels,
    datasets,
  };

  //console.log('chart by day chartData', chartData);

  // Function to handle print action
  const handlePrint = () => {
    const chart = chartRef.current;
    if (chart) {
      const canvas = chart.canvas;
      const dataUrl = canvas.toDataURL();
      const printWindow = window.open('', '_blank');
      printWindow.document.write(
        '<html><head><title>Weekly Chart</title></head><body>'
      );
      printWindow.document.write(
        `<img src="${dataUrl}" onload="window.print();window.close()" />`
      );
      printWindow.document.write('</body></html>');
      printWindow.document.close();
    }
  };

  //console.log('aggregateWorkData  is', aggregateWorkData);

  return (
    <>
      {isLoading && <Loader />}
      {isLoading ? (
        <>
          <Loader />
          <Loader /> {/* Second loader */}
        </>
      ) : error ? (
        <Message variant='danger'>
          {error?.data?.message || error.error}
        </Message>
      ) : (
        <>
          <Button variant='outline-info' size='sm' onClick={handleShow}>
            <FaCalendarWeek /> Weekly Chart
          </Button>

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                <h6>
                  Weekly Chart - Work Time: {overallTotalWorkHours} hours{' '}
                  {overallTotalWorkMinutes} minutes
                </h6>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {console.log('aggregateWorkData', aggregateWorkData)}

              {aggregateWorkData.length > 0 ? (
                <>
                  <Bar
                    ref={chartRef}
                    data={chartData}
                    options={{
                      scales: {
                        x: { stacked: true },
                        y: {
                          stacked: true,
                          max: highestDailyHours,
                        },
                      },
                      plugins: {
                        tooltip: {
                          callbacks: {
                            label: (context) => {
                              const { dataset, dataIndex } = context;
                              const workTime = dataset.data[dataIndex];
                              const percentage = (
                                (workTime /
                                  totalWorkTimePerDay[labels[dataIndex]]) *
                                100
                              ).toFixed(2);
                              return `${dataset.label
                                .split('(')[0]
                                .trim()}: ${workTime} hours (${percentage}%)`;
                            },
                          },
                        },
                        labels: {
                          display: true,
                          formatter: (value, context) => {
                            const { dataset, dataIndex } = context;
                            const percentage = (
                              (value / totalWorkTimePerDay[labels[dataIndex]]) *
                              100
                            ).toFixed(2);
                            return `${value} hours (${percentage}%)`;
                          },
                          color: 'black',
                          font: {
                            size: 12,
                          },
                          align: 'center',
                          anchor: 'center',
                        },
                      },
                    }}
                  />
                  <Button
                    variant='outline-primary'
                    size='sm'
                    onClick={handlePrint}
                  >
                    Print Chart
                  </Button>
                </>
              ) : (
                <Message variant='info'>No data available</Message>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </>
  );
}

export default WorkVolumeChart;
