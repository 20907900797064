import React, { useState, useEffect } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import { FaTrash, FaEdit, FaCheck, FaTimes } from 'react-icons/fa';
import Message from '../../components/Message';
import Loader from '../../components/Loader';
import {
  useDeleteUserMutation,
  useGetUsersQuery,
} from '../../slices/usersApiSlice';
import { toast } from 'react-toastify';

import { DataGrid, GridToolbar, gridClasses } from '@mui/x-data-grid';
import {
  grey,
  yellow,
  common,
  orange,
  lightGreen,
  red,
} from '@mui/material/colors';
import { darken, lighten, styled } from '@mui/material/styles';
import '../../utils/tableStack.css';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';

const dropdownStyle = {
  backgroundColor: '#F5F5F5', //'#F0E68C'                 //'lightyellow',
  // Add other styles as needed
};
const textStyle = {
  backgroundColor: '#f5f5dc',
  // Add other styles as needed
};

const UserListScreen = () => {
  const { data: users = [], refetch, isLoading, error } = useGetUsersQuery();
  const [deleteUser] = useDeleteUserMutation();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const { userInfo } = useSelector((state) => state.auth);
  let filteredUsers;
  const navigate = useNavigate();
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (users && Array.isArray(users)) {
    const usersCopy = [...users];

    if (!userInfo.isRadAdmin) {
      filteredUsers = usersCopy
        .filter((user) => !user.isRadAdmin)
        .sort((a, b) => a.name.localeCompare(b.name)); // Sort by name
    } else {
      filteredUsers = usersCopy.sort((a, b) => a.name.localeCompare(b.name)); // Sort by name
    }
  }

  const deleteHandler = async (id) => {
    if (window.confirm('Are you sure')) {
      try {
        //await deleteUser(id);
        refetch();
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    }
  };

  const handleEdit = (id) => {
    console.log(`Edit item with ID: ${id}`);
    //console.log(`Edit item with ID: ${uKey}`);

    //navigate(`/worklist/${id}/activity/${uKey}`);
    navigate(`/admin/user/${id}/edit`);
  };

  const renderErrorOrLoading = () => {
    if (isLoading) return <Loader />;
    if (error)
      return (
        <Message variant='danger'>
          {error?.data?.message || error.error}
        </Message>
      );
    return null;
  };

  // Columns for the DataGrid
  const columns = [
    { field: 'speciality', headerName: 'Speciality', width: 150 },
    { field: 'name', headerName: 'Name', width: 150 },
    { field: 'email', headerName: 'Email', width: 200 },
    {
      field: 'isAdmin',
      headerName: 'Admin',
      width: 120,
      renderCell: (params) =>
        params.value ? (
          <FaCheck style={{ color: 'green' }} />
        ) : (
          <FaTimes style={{ color: 'red' }} />
        ),
    },
    {
      field: 'areyouaclinicaldirector',
      headerName: 'Manager',
      width: 100,
      renderCell: (params) =>
        params.value ? (
          <FaCheck style={{ color: 'green' }} />
        ) : (
          <FaTimes style={{ color: 'red' }} />
        ),
    },
    {
      field: 'isLocum',
      headerName: 'Locum',
      width: 100,
      renderCell: (params) =>
        params.value ? (
          <FaCheck style={{ color: 'green' }} />
        ) : (
          <FaTimes style={{ color: 'red' }} />
        ),
    },
    {
      field: 'locumEndDate',
      headerName: 'Locum End Date',
      width: 100,
      renderCell: (params) =>
        params.value ? new Date(params.value).toLocaleDateString('en-GB') : '',
    },
    {
      field: 'actions',
      headerName: 'Actions',

      width: 250,
      renderCell: (params) => (
        <>
          <LinkContainer to={`/admin/user/${params.row.id}/edit`}>
            <Button variant='light'>
              <FaEdit /> Edit
            </Button>
          </LinkContainer>

          <Button variant='light' onClick={() => deleteHandler(params.row._id)}>
            <FaTrash /> Delete
          </Button>
        </>
      ),
    },
  ];

  // Data for the DataGrid
  const rows = filteredUsers.map((user) => ({
    id: user._id,
    speciality: user.speciality,
    name: user.name,
    email: user.email,
    isAdmin: user.isAdmin,
    areyouaclinicaldirector: user.areyouaclinicaldirector,
    isLocum: user.isLocum,
    locumEndDate: user.locumEndDate,
  }));

  const renderUsersTable = () => (
    //<div style={{ height: 700, width: '100%' }}>
    <Box sx={{ height: 800, width: '100%' }}>
      <strong>Admin- List of users : </strong>

      <DataGrid
        density='compact'
        rows={rows}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        //components={{ Toolbar: GridToolbar }}
        pageSizeOptions={[50]}
        checkboxSelection
        disableRowSelectionOnClick
        getRowClassName={(params) => {
          if (params.row.isLocum) {
            return 'lightGreenRow';
          } else if (params.row.locumEndDate) {
            return 'orangeRow';
          } else if (params.row.name.startsWith('andrew')) {
            return 'redRow';
          }
          return '';
        }}
        getRowSpacing={getRowSpacing}
      />
    </Box>
    //</div>
  );

  const [responsive, setResponsive] = useState('vertical');
  const [tableBodyHeight, setTableBodyHeight] = useState('400px');
  const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState('');
  const [searchBtn, setSearchBtn] = useState(true);
  const [downloadBtn, setDownloadBtn] = useState(true);
  const [printBtn, setPrintBtn] = useState(true);
  const [viewColumnBtn, setViewColumnBtn] = useState(true);
  const [filterBtn, setFilterBtn] = useState(true);

  const options = {
    search: searchBtn,
    download: downloadBtn,
    print: printBtn,
    viewColumns: viewColumnBtn,
    filter: filterBtn,
    filterType: 'dropdown',
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    onTableChange: (action, state) => {
      //console.log(action);
      console.dir(state);
    },
  };

  const getRowSpacing = React.useCallback((params) => {
    return {
      top: params.isFirstVisible ? 0 : 1,
      bottom: params.isLastVisible ? 0 : 1,
    };
  }, []);
  //
  //
  const getBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);

  const getHoverBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

  const getSelectedBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

  const getSelectedHoverBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.4);
  //

  const StyledDataGrid = styled(DataGrid)`
    & .${gridClasses.row}.lightGreenRow {
      background-color: ${lightGreen[100]};
    }
    & .${gridClasses.row}.yellowRow {
      background-color: ${yellow[100]};
    }
    & .${gridClasses.row}.redRow {
      background-color: ${red[100]};
    }

    & .${gridClasses.row}.orangeRow {
      background-color: ${orange[100]};
    }

    &
      .${gridClasses.row}:nth-child(odd):not(.yellowRow):not(.orangeRow):not(
        .lightGreenRow
      ):not(.redRow) {
      background-color: ${grey[200]};
    }

    &
      .${gridClasses.row}:nth-child(even):not(.yellowRow):not(.orangeRow):not(
        .lightGreenRow
      ):not(.redRow) {
      background-color: ${common.white}; // Assuming 'common.white' was intended
    }
  `;

  //

  const renderUsersTable2 = () => (
    //<div style={{ height: 700, width: '100%' }}>
    <Box sx={{ height: 800, width: '100%' }}>
      <strong>Admin- List of users : </strong>

      <StyledDataGrid
        density='compact'
        rows={rows}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        //components={{ Toolbar: GridToolbar }}
        pageSizeOptions={[50]}
        checkboxSelection
        disableRowSelectionOnClick
        getRowClassName={(params) => {
          const today = new Date();
          const threeWeeksFromToday = new Date();
          threeWeeksFromToday.setDate(today.getDate() + 21); // 21 days = 3 weeks

          // Parse `locumEndDate` into a Date object
          const locumEndDate = params.row.locumEndDate
            ? new Date(params.row.locumEndDate)
            : null;

          // Debugging logs
          console.log('Row:', params.row);
          console.log('Locum End Date:', locumEndDate);

          // Check if locumEndDate is older than today or within 3 weeks
          if (params.row.isLocum && !params.row.locumEndDate) {
            return 'orangeRow';
          } else if (
            locumEndDate &&
            (locumEndDate.getTime() < today.getTime() ||
              locumEndDate.getTime() <= threeWeeksFromToday.getTime())
          ) {
            console.log('Marking as redRow:', params.row);
            return 'redRow';
          } else if (params.row.isLocum) {
            return 'lightGreenRow';
          }
          return '';
        }}
        getRowSpacing={getRowSpacing}
      />
    </Box>
    //</div>
  );

  const renderUsersCards = () => (
    <Row>
      {filteredUsers.map((user) => (
        <Col key={user._id} md={4}>
          <Card className='mb-3'>
            <Card.Body>
              <Card.Title>{user.name}</Card.Title>
              <Card.Text>
                <strong>Email:</strong>{' '}
                <a href={`mailto:${user.email}`}>{user.email}</a>
              </Card.Text>
              <Card.Text>
                <strong>Admin:</strong>{' '}
                {user.isAdmin ? (
                  <FaCheck style={{ color: 'green' }} />
                ) : (
                  <FaTimes style={{ color: 'red' }} />
                )}
              </Card.Text>
              <Card.Text>
                <strong>Manager:</strong>{' '}
                {user.areyouaclinicaldirector ? (
                  <FaCheck style={{ color: 'green' }} />
                ) : (
                  <FaTimes style={{ color: 'red' }} />
                )}
              </Card.Text>
              <Card.Text>
                <strong>Locum:</strong>{' '}
                {user.isLocum ? (
                  <FaCheck style={{ color: 'green' }} />
                ) : (
                  <FaTimes style={{ color: 'red' }} />
                )}
                {user.isLocum && user.locumEndDate && (
                  <span>
                    {' '}
                    (Ends:{' '}
                    {new Date(user.locumEndDate).toLocaleDateString('en-GB')})
                  </span>
                )}
              </Card.Text>
              <div>
                <Button variant='light' onClick={() => deleteHandler(user._id)}>
                  <FaTrash />
                </Button>
                <LinkContainer to={`/admin/user/${user._id}/edit`}>
                  <Button variant='light'>
                    <FaEdit />
                  </Button>
                </LinkContainer>
              </div>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
  );

  return (
    <>
      <h1>Users</h1>
      {renderErrorOrLoading()}
      {windowWidth < 900 ? renderUsersCards() : renderUsersTable2()}
    </>
  );
};

export default UserListScreen;
